import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { store } from '../store/store';
import Api, {baseURL} from '../api/Api';
import Navbar from '../component/Navbar';
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Component for displaying product details
const ProductDetails = ({ product, isArabic }) => (
    <div className="flex flex-col items-start p-4">
        <div className="flex items-end justify-between w-full gap-1">


        <h1 className="text-2xl sm:text-3xl font-semibold">
            {isArabic ? product.name_AR : product.name_EN}
        </h1>

            {product.pdf_file_url && <a href={baseURL + product.pdf_file_url}>
                <button className="p-2 bg-amber-400 rounded-xl text-[#525555] hover:bg-amber-300 hover:text-black">
                    {isArabic ? "فتح الملف " : "open file "}
                    <FontAwesomeIcon icon={faFilePdf} style={{color: '#525555', fontSize: '20px'}}/>

                </button>
            </a>}
        </div>
        <p className="text-gray-500 mt-2">
            {isArabic ? product.description_AR : product.description_EN}
        </p>
    </div>
);

// Component for displaying the main image
const MainImageDisplay = ({image}) => (
    <div className="flex justify-center items-center p-4">
      {
          image && <img src={image} alt="Main Product" className="rounded-lg max-w-full max-h-full object-cover shadow-lg" />
      }
  </div>
);

// Component for displaying thumbnail images
const ThumbnailGallery = ({ images, setImageIndex, selectedImageIndex }) => (
  <div className="flex lg:flex-col space-x-2 lg:space-x-0 lg:space-y-2 overflow-auto lg:overflow-visible p-4">
    { images.map((img, index) => (
      <img
        key={index}
        src={img.image}
        alt={`Thumbnail ${index + 1}`}
        onClick={() => setImageIndex(index)}
        className={`cursor-pointer w-20 h-20 rounded-lg border-2 transition-all ${
          selectedImageIndex === index ? 'border-pink-500' : 'border-transparent'
        }`}
      />
    ))}
  </div>
);

const ProductDisplay = observer(() => {
  const [selectedImageIndex, setImageIndex] = useState(0);
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const isArabic = store.lang === 'ar';

  // Fetch product data on component mount
  useEffect(() => {
    Api.get(`products/${id}`)
      .then((res) => setProduct(res.data))
      .catch((err) => console.error(err));
  }, [id]);

  // Show loading message until product data is fetched
  if (!product) {
    return <div className="text-center py-8">Loading...</div>;
  }

  return (
    <>
      <Navbar />
      <div className={`container mx-auto px-4 py-8 max-w-6xl ${isArabic ? 'text-right' : 'text-left'}`} dir={isArabic ? 'rtl' : 'ltr'}>
        <div className="flex flex-col lg:flex-row items-center lg:items-start space-y-6 lg:space-y-0 lg:space-x-4">
          
          {/* Product Details */}
          <div className="lg:w-5/12 w-full">
            <ProductDetails product={product} isArabic={isArabic} />
          </div>

          {/* Main Image Display */}
          <div className="lg:w-5/12 w-full flex justify-center">
            <MainImageDisplay image={product?.images[selectedImageIndex]?.image} />
          </div>

          {/* Thumbnail Gallery */}
          <div className="lg:w-2/12 w-full flex lg:flex-col justify-center">
            <ThumbnailGallery
              images={product.images}
              setImageIndex={setImageIndex}
              selectedImageIndex={selectedImageIndex}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default ProductDisplay;
