import axios from 'axios';

const Api = axios.create({
  baseURL: 'https://admin.wafaa-alkhaleej.com/api/',
});
export default Api;



export const baseURL = 'https://admin.wafaa-alkhaleej.com';







