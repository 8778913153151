import React from "react";
import bathImage from "../assest/images/bathroom.png";
import etihadLogo from "../assest/images/partner/1.png";
import iraqiLogo from "../assest/images/partner/2.png";
import turkishLogo from "../assest/images/partner/3.png";
import { observer } from "mobx-react-lite";
import { store } from "../store/store";
import {Link, useNavigate} from "react-router-dom";

const AboutSection = observer(() => {
  let navigate = useNavigate();

  return (
    <div className="container mx-auto my-16 px-4">
      <div className="flex flex-col md:flex-row items-center md:items-center">
        {/* Left Column - Image */}
        <div className="relative w-full md:w-2/6 mb-8 md:mb-0">
          <div className="relative overflow-hidden rounded-full md:rounded-tl-[120px]">
            <img
              src={bathImage}
              alt="Bathroom"
              className="object-cover w-[100%] h-full"
            />
            <div className="absolute -bottom-6 -right-6 w-24 h-24 bg-blue-200 rounded-full transform rotate-45 opacity-75"></div>
          </div>
        </div>

        {/* Right Column - Text */}
        <div
          className="w-full md:w-1/2 md:pl-12 text-center md:text-start"
          dir={store.lang === "en" ? "ltr" : "rtl"}>
          <h2 className="text-3xl font-bold mb-4">
            {store.lang === "en" ? "About Us" : "من نحن"}
          </h2>
          <p className="text-gray-700 leading-relaxed mb-6">
            {store.lang === "en"
              ? `Wafaa Al Rafdin & Shouaa Al Khaleej company is an Iraqi company that was
            established in Baghdad. The company operates in the field of bathrooms and
            sanitary ware with a passion for achieving excellence in this field. The company
            has been working with Turkish and European companies since 2010, reflecting the
            expansion and prosperity that the company has achieved over the years.`
              : `وفاء الرافدين وشعاع الخليج هي شركة عراقية تأسست في بغداد. تعمل الشركة في مجال الحمامات والأدوات الصحية بشغف لتحقيق التميز في هذا المجال. وقد عملت الشركة مع شركات تركية وأوروبية منذ عام 2010، مما يعكس التوسع والازدهار الذي حققته الشركة على مر السنين.`}
          </p>
          <p className="text-gray-700 leading-relaxed">
            {
              store.lang === "en"
                ? `Wafaa Al Rafdin & Shouaa Al Khaleej is committed to quality and
            excellence in the field of bathrooms and sanitary ware. It has
            continued to provide its services even after its temporary
            suspension during the difficult conditions in Iraq. In collaboration
            with Turkish and European companies, it is capable of offering
            distinguished and advanced products and services in this field.`
            :`وفاء الرافدين وشعاع الخليج ملتزمة بالجودة والتميز في مجال الحمامات والأدوات الصحية. وقد استمرت في تقديم خدماتها حتى بعد توقفها المؤقت خلال الظروف الصعبة في العراق. وبالتعاون مع شركات تركية وأوروبية، فهي قادرة على تقديم منتجات وخدمات متميزة ومتقدمة في هذا المجال.`
            }
          </p>
        </div>
      </div>

      {/* Our Agents Section */}
      <div className="mt-16 text-center  ">
        <h3 className="text-2xl font-bold text-blue-500 mb-6">{
          store.lang === "en" ? "Our Agencies" : "وكالاتنا" }</h3>
          <div className="flex flex-wrap justify-center sm:justify-around items-center bg-[#EFF1F3]">

              <div
                  onClick={() => {
                      navigate(`/products`, {state: {brand: 'GEBERIT'}});
                  }}
              >
                  <img src={iraqiLogo} width={300} alt="Iraqi Airways" className=" sm:w-30"/>
              </div>

              <div
                  onClick={() => {
                      navigate(`/products`, {state: {brand: 'vitra'}});
                  }}
              >
                  <img src={etihadLogo} width={200} alt="Etihad Airways" className=" sm:w-30"/>
              </div>
              <div
                  onClick={() => {
                      navigate(`/products`, {state: {brand: 'Tthermex'}});
                  }}
              >
                  <img src={turkishLogo} width={200} alt="Turkish Airlines" className=" sm:w-30"/>
              </div>
          </div>

      </div>
    </div>
  );
});

export default AboutSection;
