import React, { useEffect } from 'react';
import productImage from '../assest/images/frame.png'; // Replace with the actual path to your image
import { observer } from 'mobx-react-lite';
import { store } from '../store/store';
import API from '../api/Api';
import categoryImage from '../assest/images/categrary.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';

const ProductsSection = observer(() => {
  const [data, setData] = React.useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    API.get('categories')
      .then((res) => {
        setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={`container mx-auto py-16 px-8`} dir={store.lang === 'en' ? 'ltr' : 'rtl'}>
      {/* Header Section */}
      <h2 className="text-3xl font-bold text-gray-900 mb-12">
        {store.lang === 'en' ? 'Categories' : 'التصنيفات'}
      </h2>

      {/* Products Slider */}
      <Swiper
        slidesPerView={1}
        spaceBetween={5}
        breakpoints={{
          640: { slidesPerView: 2, spaceBetween: 20 },
          768: { slidesPerView: 3, spaceBetween: 30 },
          1024: { slidesPerView: 4, spaceBetween: 40 },
        }}
        pagination={false}
        navigation={false}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {data.length > 0 &&
          data.map((category) => (
            <SwiperSlide key={category.id} className="group overflow-hidden rounded-lg">
              <div className="relative overflow-hidden" onClick={(e) => {
                e.preventDefault();
                navigate(`/products`, { state: { id: category.id } });
              }}>
                <img
                  src={category.image || categoryImage}
                  alt={category.id}
                  className="w-full h-64 object-cover transform transition duration-300 ease-in-out group-hover:scale-105"
                />
                <div className="absolute top-4 left-4 w-12 h-12 rounded-full flex items-center justify-center overflow-hidden shadow-md">
                  <img src={category.image || categoryImage} alt={category.id} className="object-cover w-full h-full" />
                </div>
              </div>
              <div className="mt-4 text-start">
                <p className="text-lg font-semibold text-gray-800">
                  {store.lang === 'en' ? category.name_EN : category.name_AR}
                </p>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
});

export default ProductsSection;
