import { makeAutoObservable } from "mobx";

class Store {
  lang = 'en';

  constructor() {
    makeAutoObservable(this);
  }

  setLang(lang) {
    this.lang = lang;
  }
}

export const store = new Store();
