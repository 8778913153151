import React, { useEffect } from 'react';
import project1 from '../assest/images/frame.png'; // Replace with actual paths to your images

import { observer } from 'mobx-react-lite';
import Api from '../api/Api';
import { store } from '../store/store';
import { Link } from 'react-router-dom';
import ResponsiveModal from './ResponsiveModal';

const ProjectsSection = observer(() => {

  const [projects, setProjects] = React.useState([]);
  const [modalinfo, setModalInfo] = React.useState({
    title: '',
    description: '',
  });
  const [isOpen, setIsOpen] = React.useState(false);




  useEffect(() => {
   Api.get('projects').then((res) => {
    setProjects(res.data.results)
    console.log(res.data.results)
   })
   .catch((err) => {
    console.log(err)
   })
  }, []);

 
  return (
    <div className="container mx-auto py-16 px-8 bg-gray-900 text-white" dir={store.lang === 'en' ? 'ltr' : 'rtl'}>
      {/* Header Section */}
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between mb-10">
        <div>
          <h2 className="text-3xl font-bold mb-2">
            {store.lang === 'en' ? 'Our projects' : 'مشاريعنا'}
          </h2>
          <h3 className="text-xl text-blue-400">
            {store.lang === 'en' ? 'WE ARE INVOLVED IN' : 'نحن مشاركين في'}
          </h3>
        </div>
       <Link to="/projects">{
          store.lang === 'en' ? (<button className="mt-6 lg:mt-0 bg-gray-800 text-white py-2 px-6 rounded-md flex items-center">
            Show All <span className="ml-2">➤</span>
          </button>) : (<button className="mt-6 lg:mt-0 bg-gray-800 text-white py-2 px-6 rounded-md flex items-center">
            
             عرض الكل  <span className="mr-2">◀ </span> 
          </button>)
        }
        </Link>
      </div>

      {/* Projects Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
        {projects.length > 0 && projects.slice(0, projects.length > 8 ? 8 : 4).map((project, index) => (
          <Link onClick={()=>{
           
            setModalInfo({
              title: store.lang === 'en' ? project.name_EN : project.name_AR,
              description: store.lang === 'en' ? project.description_EN : project.description_AR,
      
            })
            setIsOpen(true)
          }}>
          <div key={index} className="relative group overflow-hidden">
            <img
              src={project.image || project1}
              alt={project.id}
              className="object-cover w-full h-48 sm:h-60 lg:h-64 transform transition duration-300 ease-in-out group-hover:scale-110"
            />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white p-4 opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
              {
                store.lang === 'en' ? (
                  <h3 className="text-lg font-bold mb-2">{project.name_EN}</h3>
                ) : (
                  <h3 className="text-lg font-bold mb-2 text-right">{project.name_AR}</h3>
                )
              }
            </div>
          </div>
          </Link>
        ))}

        
      </div>
      <ResponsiveModal isOpen={isOpen} title={modalinfo.title} description={modalinfo.description} setIsOpen={setIsOpen} />
    </div>
  );
});

export default ProjectsSection;
