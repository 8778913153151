import React from 'react';
import backgroundImage from '../assest/images/home.png'; // تأكد من استبدال المسار بالصورة الفعلية لديك
import { observer } from 'mobx-react-lite';
import { store } from '../store/store';

const ContactUsSection = observer(() => {
  const isArabic = store.lang === 'ar';
  return (
    <div
    id='contact'
      className="relative w-full h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
      dir={isArabic ? 'rtl' : 'ltr'}
    >
      {/* طبقة التعتيم */}
      <div className="absolute inset-0 bg-black bg-opacity-70"></div>

      {/* قسم المحتوى */}
      <div className="relative z-10 container mx-auto h-full flex flex-col items-start justify-center px-8 md:px-16 text-white">
        <h2 className="text-5xl font-bold mb-6">{isArabic ? 'اتصل بنا' : 'Contact Us'}</h2>
        <p className="text-xl leading-relaxed mb-8 max-w-2xl">
          {
            isArabic
              ? `فريقنا جاهز للإجابة على جميع استفساراتكم ومساعدتكم في اختيار أفضل برامج الحمامات والأدوات الصحية. لا تترددوا في الاتصال بنا عبر الهاتف أو البريد الإلكتروني. سنكون سعداء بخدمتكم.`
              : `
          Our team is ready to answer all your questions and help you choose the best bathroom programs and sanitary ware. Please feel free to contact us by phone or email. We will be happy to serve you.
              `
          }
        </p>
        <div className="space-y-8">
          {/* معلومات العنوان */}
          <div className="flex items-start gap-2">
            <span className=" text-blue-400 font-semibold">{
              isArabic ? "بغداد:" : "Baghdad:"}</span>
            <p>
              {
                isArabic
                  ? "شركة وفاء الرافدين وشعاع الخليج، المنصور، بجانب برج بغداد، 07738432434"
                  : " Wafaa Al-Rafidain and Shua'a Al-Khaleej Company, Al-Mansour, next to Baghdad Tower, 07738432434"

              }
            </p>
          </div>
          <div className="flex items-start gap-2">
            <span className=" text-blue-400 font-semibold">{
              isArabic ? "بغداد:" : "Baghdad:"}</span>
            <p>
              {
                isArabic
                  ? "شركة وفاء الرافدين وشعاع الخليج،  كمب سارة، مقابل جامع الشكري، 07738432437"
                  : "Wafaa Al-Rafidain and Shua'a Al-Khaleej Company,Camp sarah, opposite Al-Shukri Mosque, 07738432437"
              }
            </p>
          </div>
          <div className="flex items-start gap-2">
            <span className=" text-blue-400 font-semibold">{
              isArabic ? "النجف الاشراف: " : "Al-Najaf Al-Ashraf:" 
              }</span>
            <p>
              {
                isArabic
                  ? ` شركة وفاء الرافدين وشعاع الخليج، حي العدالة، خدمي الحزام الأخضر، 07809000853`
                  : "Wafaa Al-Rafidain and Shua'a Al-Khaleej Company, Al-Adala District, Green Belt Service Area, 07809000853"
              }
            </p>
          </div>
          {/* معلومات البريد الإلكتروني */}
          <div className="flex items-center gap-2">
            <span className=" text-blue-400 font-semibold">{
              isArabic ? "البريد الإلكتروني:" : "Email:"}</span>
            <p>info@wafaa-alkhaleej.com</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ContactUsSection;
