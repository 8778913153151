import React from 'react';
import { FaInstagram, FaLinkedin, FaFacebook } from 'react-icons/fa';
import logo from '../assest/images/logo.png'; // استبدل بمسار الشعار لديك
import { observer } from 'mobx-react-lite';
import { store } from '../store/store';
import { HashLink } from 'react-router-hash-link';
import codi from '../assest/codi.png';

const Footer = observer(() => {
  const isArabic = store.lang === 'ar';

  const navmenu = store.lang === "en" ? ["Home", "About Us", "Our Projects", "Our Product", "Contact Us"] : ["الصفحة الرئيسية", "من نحن", "مشاريعنا", "منتجاتنا", "اتصل بنا"];
  const linkMenu = ["/", "/#about", "/projects", "/products", "/#contact"]
  return (
    <footer className="bg-gray-100">
      <div className="container mx-auto px-8 text-center">
        {/* Logo */}
        <div className="mb-6">
          <img src={logo} alt="Wafaa Al Rafdin & Shouaa Al Khaleej" className="mx-auto h-16" />
        </div>

        {/* Company Name */}
        <h2 className="text-xl font-medium text-gray-800 mb-6">
          {
            isArabic ? "شركة وفاء الرفدين وشعاع الخليج" : "Wafaa Al Rafdin & Shouaa Al Khaleej"
          }
        </h2>

        {/* Navigation Links */}
        <nav className="mb-8">
          <ul className="flex flex-wrap justify-center space-x-8 text-gray-700 text-sm font-semibold">
            {navmenu.map((item, index) => (
              <li key={index}>
                <HashLink smooth to={linkMenu[index]} className="text-gray-700 hover:text-gray-900">
                  {item}
                </HashLink>

             

              </li>
         
            ))}

          </ul>
        </nav>

        {/* Social Media Links */}
        <div className="flex justify-center space-x-8 mb-12">
          <a href="https://www.instagram.com/wk_bath?igsh=ZGx3aGlsZWhlN3Zj" className="text-gray-700 hover:text-gray-900">
            <FaInstagram size={24} />
          </a>
          <a href="https://linkedin.com" className="text-gray-700 hover:text-gray-900">
            <FaLinkedin size={24} />
          </a>
          <a href="https://www.facebook.com/share/19jbRXcCHN/?mibextid=LQQJ4d" className="text-gray-700 hover:text-gray-900">
            <FaFacebook size={24} />
          </a>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="bg-gray-800 text-gray-400 py-4 flex justify-center items-center space-x-2">
        <p className="text-center text-sm">
        Made with love by </p>
        <a href="https://codi-iq.net/" className="text-white">
        <img src={codi} alt="" width={60} />
        </a>
         <p className="text-center text-sm"> | All Right Reserved
        </p>
      </div>
    </footer>
  );
});

export default Footer;
