import React, { useEffect } from 'react';
import ProjectComponent from '../component/ProjectComponent';
import Navbar from '../component/Navbar';
import { observer } from 'mobx-react-lite';
import { store } from '../store/store';
import Api from '../api/Api';


const ProjectsPage = observer(() => {

const [projects, setProjects] = React.useState([]);
const isArabic = store.lang === 'ar';


  useEffect(() => {
    window.scrollTo(0, 0);
    Api.get('projects').then((res) => {
      setProjects(res.data.results)
      console.log(res.data.results)
     })
     .catch((err) => {
      console.log(err)
      
    })
  }, []);


  return (
    <>
      <Navbar page='projects' />
    <div className='p-20' dir={isArabic ? 'rtl' : 'ltr'}>

     
   
        <h1 className="text-4xl font-bold mb-4 text-[#535555]">
            {store.lang === 'en' ? 'Our projects' : 'مشاريعنا'}
        </h1>
        <h3 className="text-4xl text-blue-400 font-bold">
            {store.lang === 'en' ? 'WE ARE INVOLVED IN' : 'نحن مشاركين في'}
        </h3>
     

        <div className="my-10" >
          {projects.length > 0 && projects.map((project, index) => (

            <>
             <ProjectComponent key={index} project={project} dir={isArabic ? (index % 2 === 0 ? 'rtl' : 'ltr'): (index % 2 !== 0 ? 'rtl' : 'ltr')} />
            </>
          ))}
        </div>
     
     
    </div>
    </>

  );
});

export default ProjectsPage;
