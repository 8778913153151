import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import image from '../assest/images/logoNav.png'
import { store } from '../store/store'
import { FiMenu, FiX } from 'react-icons/fi'; // Import icons
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Navbar = observer(({page}) => {
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle menu

  const navmenu = store.lang === "en" ? ["Home", "About Us", "Our Projects", "Our Product", "Contact Us"] : ["الصفحة الرئيسية", "من نحن", "مشاريعنا", "منتجاتنا", "اتصل بنا"];
  const linkMenu =  ["/", "/#about", "/projects", "/products", "/#contact"]
  return (
    
      <div className="flex flex-col md:flex-row p-3 w-full bg-[#535555] items-center justify-between  shadow-lg" dir={store.lang === "en" ? "ltr" : "rtl"}>
        <div className="flex items-center justify-between w-full md:w-auto">
          <img src={image} alt="logo" className="w-16 md:w-20" />
          <button
            className="md:hidden text-white focus:outline-none"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />} {/* Toggle icon */}
          </button>
        </div>

        <div
          className={`${
            menuOpen ? 'flex' : 'hidden'
          } flex-col md:flex md:flex-row md:items-center w-full md:w-auto space-y-3 md:space-y-0 md:space-x-6 mt-3 md:mt-0`}
        >
          <ul className="flex flex-col md:flex-row items-center space-y-3 md:space-y-0 md:space-x-6">
          {navmenu.map((item, index) => (<HashLink smooth to={linkMenu[index]} activeClassName="text-red-500"  >
              <li
                key={index}
                className={`${(page === 'projects' && index === 2) || (page === 'products' && index === 3) ? 'font-bold text-[#6989b5]' : 'text-white' }  hover:text-gray-400 cursor-pointer mx-2`}
              >
                {item}
              </li></HashLink>
            ))}
          </ul>

          <div className="flex justify-center mt-3 md:mt-0">
            <select
              className="cursor-pointer text-white"
              style={{ backgroundColor: 'transparent' }}
              name="lang"
              id="lang"
              value={store.lang}
              onChange={(e) => store.setLang(e.target.value)}
            >
              <option className="text-black" value="en">EN</option>
              <option className="text-black" value="ar">AR</option>
            </select>

           
          </div>
        </div>
        <div>
        </div>
      </div>
  );
});

export default Navbar;
