
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './component/footer.jsx';
import Home from './screen/HomeScreen.jsx';
import ProjectsPage from './screen/ProjectsPage.jsx';
import Products from './screen/ProductsPage.jsx';
import Product from './screen/Product.jsx';

function App() {
  return (
    <div className="container mx-auto  ">
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" active element={<Home />} /> 
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/products" element={<Products />} />
        <Route path="/product/:id" element={<Product />} />
     
      </Routes> 
      <Footer />
    </BrowserRouter>
     
    </div>
  );
}

export default App;
