import React from 'react'
import image from '../assest/images/home.png'
import AboutSection from '../component/AboutSection'
import CompanySection from '../component/CompanySection '
import ProjectsSection from '../component/ProjectsSection'
import ProductsSection from '../component/ProductsSection'
import ContactUsSection from '../component/ContactUsSection'
import NavMain from '../component/NavMain'
import { store } from '../store/store'
import { observer } from 'mobx-react-lite'
import { HashLink } from 'react-router-hash-link'

const HomeScreen = observer(() => {

  

  const isArabic = store.lang === "ar";

  return (
    <>
      <div className="relative w-full h-screen overflow-hidden" dir={store.lang === "en" ? "ltr" : "rtl"}>
        <NavMain />
        {/* Background Image */}
        <div
        
         
          className="absolute inset-0 bg-cover bg-center bg-fixed"
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "90% 120%",
            backgroundPosition: `${isArabic ? "left top" : "right top"}`,
            animation: `${!isArabic && "slideBg 20s infinite linear"}`,
          }}
        ></div>

        {/* Overlay Content */}
        <div
          className={`relative z-9 flex flex-col items-start justify-center h-full w-[80%] p-8 ${
            isArabic
              ? "bg-gradient-to-l from-white via-white/50 to-transparent"
              : "bg-gradient-to-r from-white via-white/50 to-transparent"
          }`}
        >
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            {isArabic ? "وفاء الرافدين وشعاع الخليج" : "Wafaa Al Rafdin & Shouaa Al Khaleej"}
          </h1>
          <p className="text-gray-700 mb-6 max-w-lg">
            {isArabic
              ? "وفاء الرافدين وشعاع الخليج، ومقرها بغداد، متخصصة في الحمامات الفاخرة والأدوات الصحية، وتعاونت مع علامات تجارية تركية وأوروبية منذ عام 2010."
              : "Wafaa Al Rafdin & Shouaa Al Khaleej, based in Baghdad, specializes in premium bathrooms and sanitary ware, partnering with Turkish and European brands since 2010."}
          </p>
          <HashLink smooth to="/#contact">
          <button className="px-6 py-3 bg-gray-800 text-white rounded-md shadow-lg hover:bg-gray-700">
            {isArabic ? "اتصل بنا" : "Call Us"}
          </button></HashLink>
        </div>
      </div>

      <div id='about' isArabic={isArabic}>
        <AboutSection />

      </div>
      <CompanySection />
      <ProjectsSection />
      <ProductsSection />
      <ContactUsSection />

    </>
  )
});

export default HomeScreen;