import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { store } from '../store/store';
import { Link } from 'react-router-dom';
import ResponsiveModal from './ResponsiveModal';

const ProjectComponent = observer(({ project, dir }) => {
  const isArabic = store.lang === 'ar';
  const [valueImage, setValueImage] = React.useState(0);
  const [animate, setAnimate] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // Start the animation and change the image after a brief delay
      setAnimate(true);
 setValueImage((prevValue) => (prevValue + 1) % 2);
      setTimeout(() => {
       
        setAnimate(false); // Reset animation state after the change
      }, 1000); // Delay in ms before updating the image (matches the animation duration)
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-16" dir={dir}>
      <div className="overflow-hidden rounded-sm">
        <img
          src={valueImage === 0 ? project.image : project.image2}
          alt={project.id}
          className={`object-cover ${animate ? 'image-slide' : ''}`} // Conditionally add animation class
          width={450}
          height={450}
        />
      </div>
      <div className="col-span-1" dir={isArabic ? 'rtl' : 'ltr'}>
        <h3 className="text-3xl font-bold">
          {isArabic ? project.name_AR : project.name_EN}
        </h3>
        <p className="text-gray-600 text-lg mt-5 max-w-xl">
          {(isArabic ? project.description_AR : project.description_EN).slice(0, 500)}...
        </p>
        <Link  className="text-blue-500 mt-5" onClick={() => setIsModalOpen(true)}>
          Read More
        </Link>
      </div>
      <ResponsiveModal  isOpen={isModalOpen} setIsOpen={setIsModalOpen} title={isArabic ? project.name_AR : project.name_EN} description={isArabic ? project.description_AR : project.description_EN}/>
    </div>
  );
});

export default ProjectComponent;
