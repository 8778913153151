import { observer } from 'mobx-react-lite';
import React from 'react';
import { store } from '../store/store';

const CompanyPurposeSection = observer(() => {
  const isArabic = store.lang === 'ar';
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between py-24 px-8 lg:px-16 bg-gray-50 relative overflow-hidden" dir={isArabic ? 'rtl' : 'ltr'}>
      
      {/* Left Column - Text Content */}
      <div className="lg:w-1/2 space-y-12 z-10">
        
        {/* Purpose Section */}
        <div>
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            {isArabic ? "أهداف الشركة" : "The company's purposes"}
          </h2>
          <p className="text-gray-700 leading-relaxed">
            {isArabic
              ? "تقديم منتجات عالية الجودة وخدمات استثنائية في مجال الحمامات والأدوات الصحية للعملاء في العراق. تعزيز التواصل والتعاون مع الشركات التركية والأوروبية لتطوير وتحسين المنتجات والتقنيات المتاحة. توسيع نطاق عمل الشركة وحضورها الجغرافي في الأسواق المحلية والإقليمية. تطوير وتدريب الموارد البشرية لتعزيز الخبرات والكفاءة في مجال الحمامات والأدوات الصحية. الامتثال لأعلى معايير السلامة والصحة المهنية في جميع العمليات والمشاريع."
              : "Providing high-quality products and exceptional services in the field of bathrooms and sanitary ware to customers in Iraq. Enhancing communication and collaboration with Turkish and European companies to develop and improve available products and technologies. Expanding the company's scope of work and geographical presence in the local and regional markets. Developing and training human resources to enhance expertise and efficiency in the field of bathrooms and sanitary ware. Complying with the highest standards of safety and occupational health in all operations and projects."
            }
          </p>
        </div>

        {/* Mission Section */}
        <div>
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            {isArabic ? "رسالة الشركة" : "The company mission"}
          </h2>
          <p className="text-gray-700 leading-relaxed">
            {isArabic
              ? "نسعى جاهدين لتقديم حل شامل ومتميز في مجال الحمامات والأدوات الصحية. نعتمد على التميز والجودة والاحترافية في خدمة عملائنا. نعمل بشغف واستمرارية لتلبية احتياجات وتطلعات عملائنا. بالإضافة إلى ذلك، نهدف إلى تحقيق نمو وتطوير مستدام في هذا المجال، بالتعاون مع شركاء موثوقين لتحقيق ذلك."
              : "We strive diligently to provide a comprehensive and distinguished solution in the field of bathrooms and sanitary ware. We rely on excellence, quality, and professionalism in serving our customers. We work passionately and consistently to meet the needs and aspirations of our clients. Furthermore, we aim for sustainable growth and development in this field, collaborating with trusted partners to achieve that."
            }
          </p>
        </div>
      </div>

      {/* Right Column - Vision Card */}
      <div className="relative lg:w-2/2 flex justify-center items-center mt-12 lg:mt-0 z-10">
        
        {/* Vision Card */}
        <div className="relative bg-white shadow-lg rounded-lg p-8 w-80 text-center z-20">
          <div className="flex justify-center mb-4">
            <div className="bg-gray-800 text-white p-4 rounded-full">
              {/* Replace with any relevant icon */}
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-2">
            {isArabic ? "رؤية الشركة" : "Business Vision"}
          </h3>
          <p className="text-gray-700 text-sm leading-relaxed">
            {isArabic
              ? "تهدف شركة وفاء الرافدين وشعاع الخليج إلى أن تبقى في قمة هرم الشركات  الرائدة في مجال الحمامات والأدوات الصحية في العراق، مع التركيز المستمر على تحقيق التميز وتقديم أعلى مستويات الجودة والخدمة."
              : "Wafaa Al Rafdin & Shouaa Al Khaleej company aims to become a pioneer in the field of bathrooms and sanitary ware in Iraq, with a constant focus on achieving excellence and delivering the highest levels of quality and service."
            }
          </p>
        </div>
      </div>

      {/* Curved Background */}
      <div className={`absolute w-[800px] h-[800px] bg-gray-200 rounded-full opacity-50 -${isArabic ? 'left' : 'right'}-1/4 top-1/2 transform -translate-y-1/2 hidden lg:block`}></div>
    </div>
  );
});

export default CompanyPurposeSection;
