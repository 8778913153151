import React, { useEffect, useState } from 'react';
import { store } from '../store/store';
import { observer } from 'mobx-react-lite';
import Navbar from '../component/Navbar';
import Api from '../api/Api';
import { Link, useLocation } from 'react-router-dom';

const ProductsPage = observer(() => {
  const isArabic = store.lang === 'ar';
  const [selectedCategrary, setSelectedCategrary] = useState('');
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [tag, setTag] = useState('');

  const location = useLocation();



  useEffect(() => {

   
   
    Api.get('categories').then((res) => {
      setCategories(res.data.results);
      if (location?.state.id) {
        setSelectedCategrary(location?.state?.id || '');

      }
      if (location?.state?.brand !== '') {
        setTag(location?.state?.brand || '');
      }
      
    }).catch((err) => {
      console.log(err)
    })



  }, []);


  useEffect(() => {
   
    Api.get(`products/?${selectedCategrary !== '' ? `category=${selectedCategrary}` : ''}${searchTerm !== '' ? `&search=${searchTerm}` : ''}${tag !== '' ? `&tag=${tag}`:''}`).then((res) => {
      setProducts(res.data.results);
    }).catch((err) => {
      console.log(err)
    })
  }, [selectedCategrary, searchTerm,tag]);

  return (
    <>
      <Navbar page="products" />
      <div className="container mx-auto px-4 py-8" dir={isArabic ? 'rtl' : 'ltr'}>
        {/* Page Title */}
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold mb-6 text-center lg:text-start">
          {selectedCategrary === '' ? (isArabic ? 'كل المنتجات' : 'All Products') : isArabic ? categories.find((category) => category.id === selectedCategrary).name_AR : categories.find((category) => category.id === selectedCategrary).name_EN}
        </h1>
        <div className="my-3 w-full">
          <select className="bg-transparent" onChange={(e) => setTag(e.target.value)} value={tag}>
            <option name="All Brand" value="">
              All Brand
            </option>
            <option name="vitra" value="vitra">
              vitra
            </option>
            <option name="GEBERIT" value="GEBERIT">
              GEBERIT
            </option>
            <option name="Tthermex" value="Tthermex">
              Tthermex
            </option>
          </select>
        </div>


        {/* Main Layout */}
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">

          {/* Sidebar for Categories */}
          <div className="col-span-1 hidden lg:block">
            {/* Sidebar only visible on large screens and above */}
            <input
                type="text"
                placeholder={isArabic ? 'بحث' : 'Search'}
                className="w-full p-2 border border-gray-300 rounded-lg mb-4"
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                }}
            />
            <ul className="space-y-2">
              <li
                  onClick={() => setSelectedCategrary('')}
                  className={`text-gray-700 cursor-pointer hover:text-blue-500 ${selectedCategrary === '' ? 'font-bold' : ''
                  }`}
              >
                {isArabic ? 'كل المنتجات' : 'All Products'}
              </li>
              {categories.map((category, index) => (
                  <li
                      key={index}
                      onClick={() => setSelectedCategrary(category.id)}
                      className={`text-gray-700 cursor-pointer hover:text-blue-500 ${selectedCategrary === category.id ? 'font-bold' : ''
                      }`}
                  >
                    {isArabic ? category.name_AR : category.name_EN}
                  </li>
              ))}
            </ul>
          </div>

          {/* Product Grid */}
          <div className="col-span-1 lg:col-span-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {products.map((product) => (
                <Link to={`/product/${product.id}`} key={product.id}>
                  <div className="border p-4 rounded-lg shadow-md hover:shadow-lg">
                    {product?.images[0]?.image && <div className="relative">
                  <span className="bg-amber-300 px-2 py-1 rounded-lg absolute top-0.5 right-0.5">
                    {product.tag}
                  </span>
                      <img
                          src={product.images[0].image}
                          alt={product.name}
                          className="w-full h-48 object-cover mb-4"
                      />
                    </div>
                    }
                    <h3 className="text-lg sm:text-xl font-semibold">
                      {isArabic ? product.name_AR : product.name_EN}
                    </h3>
                    <p className="text-gray-500 text-sm sm:text-base">{isArabic ? product.description_AR : product.description_EN}</p>
                  </div>
                </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
});

export default ProductsPage;
