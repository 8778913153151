import React from "react";

const ResponsiveModal= ({isOpen,title,description,setIsOpen}) => {
  

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
     

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={toggleModal}>
          <div className="bg-white w-full max-w-lg mx-auto p-6 rounded-lg shadow-lg relative">
            {/* زر الإغلاق */}
            <button
              onClick={toggleModal}
              className="absolute top-2 right-2 text-gray-700 hover:text-black focus:outline-none text-4xl"
            >
              &times;
            </button>

            {/* العنوان */}
            <h2 className="text-2xl font-bold mb-4 text-center z-100 text-gray-700">{title} </h2>

            {/* المحتوى */}
            <p className="text-gray-600 mb-4 text-center">
              {description}
            </p>

            {/* زر الإجراء */}
            <div className="flex justify-center">
           
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResponsiveModal;
